import React, { useState, useEffect } from "react";
import SingleService from "./SingleService";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { RAASServicesApi } from "api";
import { handleResponse } from "../../Services/fack.consumer";
import { setServices } from "../../features/raas/raasSlice";

const Services = () => {
  const dispatch = useDispatch();

  const stickyMenu = useSelector((state) => {
    return state.nav.value.stickyMenu;
  });

  const services = useSelector((state) => {
    return state.raas.value.services;
  });

  const [loading, setLoading] = useState(false);
  const handleRaasServices = (response) => {
    setLoading(false);
    if (response.totalResults > 0) {
      dispatch(setServices(response.entry));
    }
  };
  const fetchServices = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    };

    return fetch(RAASServicesApi+'?lang=ind', requestOptions)
      .then(handleResponse)
      .then(handleRaasServices)
      .catch(() => {
        setLoading(false);
      });
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fas fa-arrow-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fas fa-arrow-right"></i>
    </button>
  );
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    fetchServices();

    return () => {
      // On Destroy
    };
  }, []);

  return (
    <>
      <section className="services-area services-border pos-rel pt-80 pb-160">
        <span className="h4services-bg">
          <img src="img/home4/services/services__overlay__bg.png" alt="" />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel">
                  <h5>PT. RAAS Solusi Indonesia</h5>
                  <h1>Layanan Kami</h1>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row h4service-active"> */}
          <Slider className="h4service-active h4_services_slider" {...settings}>
            {services.map((service) => {
              return (
                <SingleService
                  key={service.id}
                  image={service.image_url}
                  name={service.service_name}
                  title={service.service_name}
                  description={service.service_description}
                />
              );
            })}
          </Slider>

          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default Services;
