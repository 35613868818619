// Redux Toolkit Step#2 - Define slice
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    stickyMenu: false,
    languages: [], //TODO Add functionality change language
  },
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setStickyMenu: (state, action) => {
      state.value.stickyMenu = action.payload;
    },
    setLanguages: (state, action) => {
      state.value.languages = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStickyMenu, setLanguages } = navSlice.actions;

export default navSlice.reducer;
