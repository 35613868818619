import React, { useState } from "react";
import Context from "./index";

const AuthProvider = (props) => {
  const [useFirebase, setUseFirebase] = useState({
    value: false,
    setValue: (newValue) => {
      setUseFirebase({ ...useFirebase, value: newValue });
    },
  });
  const [useJWT, setUseJWT] = useState({
    value: true,
    setValue: (newValue) => {
      setUseJWT({ ...useJWT, value: newValue });
    },
  });
  const [useAuth0, setUseAuth0] = useState({
    value: false,
    setValue: (newValue) => {
      setUseAuth0({ ...useAuth0, value: newValue });
    },
  });

  const [useSocialLogin, setUseSocialLogin] = useState({
    value: false,
    setValue: (newValue) => {
      setUseSocialLogin({ ...useSocialLogin, value: newValue });
    },
  });

  return (
    <Context.Provider
      value={{
        ...props,
        useFirebase,
        useJWT,
        useAuth0,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default AuthProvider;
