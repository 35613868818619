// Redux Toolkit Step#2 - Define slice
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    services: [
      {
        "service_id": "1",
        "image_url": "https:\/\/picsum.photos\/406\/190",
        "service_name": "Pengembangan Web",
        "service_description": "Kami menyediakan jasa pengembangan web yang profesional dan berkualitas tinggi. Dengan tim yang berpengalaman, kami siap membantu Anda mewujudkan website impian yang responsif, user-friendly, dan sesuai dengan kebutuhan bisnis Anda. Percayakan kebutuhan digital Anda kepada kami dan rasakan perbedaannya."
      },
    ],
  },
};

export const raasSlice = createSlice({
  name: "raas",
  initialState,
  reducers: {
    setServices: (state, action) => {
      state.value.services = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setServices } = raasSlice.actions;

export default raasSlice.reducer;
