import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      id: {
        translations: {
          General: "Umum",
          "Dashboards,Widgets": "Dashboards,Widgets",
          Dashboard: "Dashboard",
          Default: "Default",
          Ecommerce: "Ecommerce",
          Widgets: "Widgets",
          Chart: "Chart",
          Applications: "Applications",
          "Ready to use Apps": "Ready to use Apps",
          "Garudaku": "Garudaku",
          "Atomic Component": "Atomic Component",
        },
      },
      en: {
        translations: {
          General: "General",
          "Dashboards,Widgets": "Dashboards,Widgets",
          Dashboard: "Dashboard",
          Default: "Default",
          Ecommerce: "Ecommerce",
          Widgets: "Widgets",
          Chart: "Chart",
          Applications: "Applications",
          "Ready to use Apps": "Ready to use Apps",
          "Garudaku": "Garudaku",
          "Atomic Component": "Atomic Component",
        },
      },
    },
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
