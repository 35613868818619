import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { authHeader, handleResponse } from "../Services/fack.backend";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  let jwt = useSelector((state) => {
    return state.jwt.value;
  });
  if (jwt.token == null) {
    jwt = localStorage.getItem("jwt");
  }
  let jwt_token = null;
  let authenticated = false;
  if (jwt !== null) {
    jwt_token = jwt.token;
    if (jwt_token == null) jwt_token = localStorage.getItem("token");

    if (jwt_token !== null) {
      authenticated = true;
    }
  }

  useEffect(() => {
    const requestOptions = { method: "GET", headers: authHeader() };
    fetch("/users", requestOptions).then(handleResponse);
    // firebase_app.auth().onAuthStateChanged(setCurrentUser);
    // setAuthenticated(JSON.parse(localStorage.getItem('authenticated')))
  }, []);

  return authenticated && jwt_token !== null ? (
    <Outlet />
  ) : (
    <Navigate exact to={`${process.env.PUBLIC_URL}/home`} />
  );
};

export default PrivateRoute;
