import React, { Fragment, useState, useContext } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import {
  ForgotPassword,
  LoginWithJWT,
  Password,
  RememberPassword,
  SignIn,
  Username,
} from "../../../Constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../../../Config/Config";

// Redux Toolkit Step#4 - Add Selector and Dispatch
import { useDispatch } from "react-redux";

// System
import SocialAuth from "./SocialAuth";
import AuthContext from "../../../_helper/Auth";
import { AuthTokenApi, UserAccountApi } from "../../../api";
import { handleResponse } from "../../../Services/fack.consumer";
import { setJWT } from "../../../features/auth/jwtSlice";
import { setAuth } from "../../../features/auth/authSlice";

const LoginTab = ({ selected }) => {
  // Redux Toolkit Step#4.1 - Add Selector and Dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /*  const jwt = useSelector((state) => {
    return state.jwt.value;
  });*/

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("test@gmail.com");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const CacheJWT = "jwt";
  const CacheJWTToken = "token";
  const CacheRemember = "remember";

  const { useSocialLogin } = useContext(AuthContext);

  const GetLoggedUser = (payload) => {
    const jwt = payload.jwt;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt.token,
      },
    };

    return fetch(UserAccountApi, requestOptions)
      .then(handleResponse)
      .then((user) => {
        dispatch(setAuth(user));
        return user;
      })
      .catch(() => {});
  };
  const handleLoggedUser = (user) => {
    if (typeof user.name !== "undefined") {
      if (user.name !== "") {
        toast.success("Sign In Successfully, you will be redirect shortly");
        localStorage.setItem("authenticated", true);
        const missingAttribute = {
          picture: "",
        };
        const currentUser = {
          ...user,
          ...missingAttribute,
        };
        localStorage.setItem("auth0_profile", JSON.stringify(currentUser));
        setTimeout(() => {
          navigate("/dashboard/default");
        }, 2000);
      }
    }
  };

  /**
   * Remember functionality, check existing jwt token and hit in useEffect
   * @constructor
   */
  /*const CheckHasSavedJWTToken = () => {
    const saved_jwt = localStorage.getItem("jwt");
    if (saved_jwt !== "") {
      const jwt = JSON.parse(saved_jwt);
      if (jwt !== null) {
        if (typeof jwt.token !== "undefined") {
          GetLoggedUser({
            jwt,
          }).then(handleLoggedUser);
        }
      }
    }
  };*/

  /**
   * Handle authorization return from server, JSON Request or JWT Token
   * @param json
   * @returns {boolean|Promise<*>}
   */
  const handleToken = (json) => {
    if (typeof json.success !== "undefined") {
      // If JSON Request
      if (!json.success) {
        toast.error(json.message);
      } else {
        toast.error("Unrecognized response #1");
        console.error(json);
      }
    } else if (typeof json.token !== "undefined") {
      // If JWT Token
      const authToken = json;
      if (authToken) dispatch(setJWT(authToken));
      // Remember Token for next login
      localStorage.setItem(CacheJWT, JSON.stringify(authToken));
      localStorage.setItem(CacheJWTToken, authToken.token);
      if (remember) {
        localStorage.setItem(CacheRemember, true);
      }
      return GetLoggedUser({
        jwt: authToken,
      });
    } else {
      toast.error("Unrecognized response #2");
    }

    return false;
  };

  /**
   * Handle JWTLogin will hit handleToken
   * @param payload
   * @returns {Promise<* | boolean | void>}
   * @constructor
   */
  const JWTLogin = (payload) => {
    const username = payload.username;
    const password = payload.password;
    // const remember = payload.remember;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    };

    return fetch(AuthTokenApi, requestOptions)
      .then(handleResponse)
      .then(handleToken)
      .catch(() => {
        toast.error(
          "Unable to fetch to server, please continue in next 5 minutes"
        );
      });
  };

  /**
   * Action Firebase ( Not Used Yet )
   * @param e
   * @returns {Promise<void>}
   */
  const loginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEmail("test@gmail.com");
    setPassword("test123");
    try {
      await firebase_app
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(function () {
          setTimeout(() => {
            navigate("/dashboard/default");
          }, 200);
        });
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };

  /**
   * Action from button Sign In
   * @param e
   */
  const loginWithJwt = (e) => {
    setLoading(true);
    try {
      JWTLogin({username, password, remember}).then((user) => {
        setLoading(false);
        handleLoggedUser(user);
      });
    } catch (e) {
      setLoading(true);
      toast.error(
        "Unable to fetch to server, please continue in next 5 minutes"
      );
    }
  };

  return (
    <Fragment>
      <Form className="theme-form">
        <H4>{"Sign In"}</H4>
        <P>{"Enter your email & password to login"}</P>
        <FormGroup>
          <Label className="col-form-label">{Username}</Label>
          <Input
            className="form-control"
            type="text"
            required=""
            onChange={(e) => setUsername(e.target.value)}
            defaultValue={username}
            placeholder={"Your username"}
          />
        </FormGroup>
        <FormGroup className="position-relative">
          <Label className="col-form-label">{Password}</Label>
          <Input
            className="form-control"
            type={togglePassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            defaultValue={password}
            required=""
            placeholder={"Secret password"}
          />
          <div
            className="show-hide"
            onClick={() => setTogglePassword(!togglePassword)}
          >
            <span className={togglePassword ? "" : "show"}></span>
          </div>
        </FormGroup>
        <div className="form-group mb-0">
          <div className="checkbox ms-3">
            <Input
              id="checkbox1"
              type="checkbox"
              onChange={(e) => setRemember(e.target.checked)}
              checked={remember}
            />
            <Label className="text-muted" for="checkbox1">
              {RememberPassword}
            </Label>
          </div>
          <a className="link" href="#javascript">
            {ForgotPassword}
          </a>
          {selected === "firebase" ? (
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
                onClick: (e) => loginAuth(e),
              }}
            >
              {loading ? "LOADING..." : SignIn}
            </Btn>
          ) : (
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
                onClick: (e) => loginWithJwt(e),
              }}
            >
              {loading ? "LOADING..." : LoginWithJWT}
            </Btn>
          )}
        </div>
        {useSocialLogin && <SocialAuth />}
      </Form>
    </Fragment>
  );
};

export default LoginTab;
