import React from "react";
import {Col, Container, Row} from "reactstrap";

const Atomic = () => {
  return (
    <Container fluid={true} className="dashboard-default-sec">
      <Row>
        <Col xl="12">
          <h1>Button</h1>
          <p>https://reactstrap.github.io/?path=/docs/components-button--button</p>
        </Col>
      </Row>
      <Row>
        <Col xl="5">
          <Row>
            <Col xl="12" md="6">
              A
            </Col>
          </Row>
        </Col>
        <Col xl="7" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="12" md="6">
              B
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Atomic;
