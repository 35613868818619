import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import NavBar from "../../Navigation/NavBar";
import Services from "../../Services/Services";
import Heros from "../../Hero/Heros";
import Footer from "../../Footer/Footer";

const LandingDefault = () => {
  return (
    <>
      <NavBar />
      <Heros />
      <Services />
      <Footer />
    </>
  );
};

export default LandingDefault;
