import { Btn, H5 } from "AbstractElements";
import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { GarudakuStatsApi } from "api";
import { handleResponse } from "Services/fack.consumer";
import {
  Default,
} from "Constant";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";

const WeeklyStats = () => {
  const jwt = useSelector((state) => {
    return state.jwt.value;
  });

  const CacheStats = "stats_stats";
  const CacheStatSummary = "stats_summary";
  const CacheStatValid = "stats_validity";

  const jwt_token = jwt.token;
  const [startDate, setStartDate] = useState(new Date());
  const [is_send_email, setSendEmail] = useState(false);

  const [stats, setStats] = useState(
    localStorage.getItem(CacheStats) !== null
      ? JSON.parse(localStorage.getItem(CacheStats))
      : {}
  );
  const [isValidStat, setIsValidStat] = useState(
    localStorage.getItem(CacheStatValid) !== null
      ? typeof stats !== "undefined"
        ? localStorage.getItem(CacheStatValid)
        : false
      : false
  );

  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(
    localStorage.getItem(CacheStatSummary)
  );

  const handleGarudakuStats = (response) => {
    setLoading(false);
    if (response.success) {
      if (typeof response.stat !== "undefined") {
        setStats(response.stat);
        localStorage.setItem(CacheStats, JSON.stringify(response.stat));
        setSummary(response.summary);
        localStorage.setItem(CacheStatSummary, response.summary);
        setIsValidStat(true);
        localStorage.setItem(CacheStatValid, true);
      }
    }
  };
  const fetchData = (buttonEvent) => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt_token,
      },
      body: JSON.stringify({
        date: moment(startDate).format("YYYY-MM-DD"),
        is_send_email: is_send_email ? "1" : "0",
      }),
    };

    return fetch(GarudakuStatsApi, requestOptions)
      .then(handleResponse)
      .then(handleGarudakuStats)
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChange = (newValue) => {
    setStartDate(newValue);
  };

  return (
    <Card className="income-card card-primary">
      <CardBody className="text-center" style={{ minHeight: "350px" }}>
        <Form className="theme-form">
          <FormGroup className="form-group row">
            <Label className="col-sm-3 col-form-label text-end">
              {Default}
            </Label>
            <Col xl="5" sm="9">
              <div className="input-group">
                <DatePicker
                  className="form-control digits"
                  selected={startDate}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </FormGroup>
          <FormGroup className="form-group row">
            <Label className="col-sm-3 col-form-label text-end"></Label>
            <Col xl="5" sm="9">
              <div className="input-group">
                <div className="checkbox">
                  <Input
                    id="checkbox1"
                    type="checkbox"
                    onChange={(e) => setSendEmail(e.target.checked)}
                    checked={is_send_email}
                  />
                  <Label className="text-muted" for="checkbox1">
                    {"Send Email"}
                  </Label>
                </div>
              </div>
            </Col>
          </FormGroup>
          <FormGroup className="form-group row">
            <Label className="col-sm-3 col-form-label text-end"></Label>
            <Col xl="5" sm="9">
              <Btn
                attrBtn={{
                  color: "primary",
                  className: "btn-block",
                  disabled: loading ? loading : loading,
                  onClick: (e) => fetchData(e),
                }}
              >
                {loading ? "LOADING..." : "Fetch New Data"}
              </Btn>
            </Col>
          </FormGroup>
        </Form>
        {isValidStat && (
          <Fragment>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <H5>{"Statistic Garudaku"}</H5>
                  <span> {stats.from + " - " + stats.to}</span>
                </CardHeader>
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th scope="col">{"Keterangan"}</th>
                        <th scope="col">{"Nilai"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={1}>
                        <th scope="row">Total Player</th>
                        <td style={{ textAlign: "left" }}>
                          {parseInt(stats.total).toLocaleString() +
                            " (+ " +
                            parseInt(
                              stats.increment.increment
                            ).toLocaleString() +
                            ")"}
                        </td>
                      </tr>
                      <tr key={2}>
                        <th scope="row">Average</th>
                        <td style={{ textAlign: "left" }}>
                          {parseFloat(
                            stats.last_3_month.average
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr key={3}>
                        <th scope="row">Maximum</th>
                        <td style={{ textAlign: "left" }}>
                          {parseFloat(
                            stats.last_3_month.maximum
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr key={4}>
                        <th scope="row">Minimum</th>
                        <td style={{ textAlign: "left" }}>
                          {parseFloat(
                            stats.last_3_month.minimum
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr key={5}>
                        <th scope="row">First</th>
                        <td style={{ textAlign: "left" }}>
                          {parseFloat(
                            stats.last_3_month.first
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr key={6}>
                        <th scope="row">Last</th>
                        <td style={{ textAlign: "left" }}>
                          {parseFloat(stats.last_3_month.last).toLocaleString()}
                        </td>
                      </tr>
                      <tr key={7}>
                        <th scope="row">Summary</th>
                        <td style={{ textAlign: "left" }}>
                          <p>{"Report tanggal " + stats.to}</p>
                          {summary.split("\n").map((i) => {
                            return <p key={i}>{i}</p>;
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default WeeklyStats;
