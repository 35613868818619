export class ConfigDB {
	static data = {
		settings: {
			layout_type:  'ltr',
			sidebar: {
				type: 'compact-wrapper',
			},
			sidebar_setting: 'modern-sidebar',
		},
		color: {
			primary_color: '#4b4b4d',
			secondary_color: '#b7883a',
			mix_background_layout: 'light-only',
		},
		router_animation: 'fadeIn'
	}
}
export default ConfigDB;




