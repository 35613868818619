// Redux Toolkit Step#2 - Define slice
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    token: null,
    expires_in: null,
    refresh_token: null,
    scope: "",
  },
};

export const jwtSlice = createSlice({
  name: "jwt",
  initialState,
  reducers: {
    setJWT: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setJWT } = jwtSlice.actions;

export default jwtSlice.reducer;
