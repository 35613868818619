// Redux Toolkit Step#2 - Define slice
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    id: 0,
    roleId: 0,
    status: 0,
    name: "",
    email: "",
    scopes: [],
    date: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
