export function handleResponse(response) {
    if (process.env.NODE_ENV === 'development') {
        console.log('handle Response return', response)
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                localStorage.removeItem('jwt');
                localStorage.removeItem('token');
                localStorage.removeItem('profileURL');
            }
        }
        return data;
    });
}

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem('token');
    if (currentUser) {
        return { Authorization: `Bearer ${currentUser}` };
    } else {
        return {};
    }
}
