import ProfileGreeting from "./ProfileGreetingCard";
import WeeklyStats from "./WeeklyStats";
import { Container, Row, Col } from "reactstrap";
import React from "react";

const DashboardGarudaku = () => {
  return (
    <Container fluid={true} className="dashboard-default-sec">
      <Row>
        <Col xl="5" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="12" md="6" className="box-col-6 des-xl-50">
              <ProfileGreeting />
            </Col>
          </Row>
        </Col>
        <Col xl="7" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="12" md="6" className="box-col-6 des-xl-50">
              <WeeklyStats />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardGarudaku;
