// Redux Toolkit Step#1 - Define toolkit
import { configureStore } from '@reduxjs/toolkit'
// Redux Toolkit Step#3 - Add Slice here
import jwtReducer from '../features/auth/jwtSlice'
import authReducer from '../features/auth/authSlice'
import navReducer from '../features/template/navSlice'
// RAAS
import raasReducer from '../features/raas/raasSlice'

export const store = configureStore({
  reducer: {
    jwt : jwtReducer,
    auth : authReducer,
    nav : navReducer,
    raas : raasReducer,
  },
})
